import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

const routes = [
  { path: "/", name: "home", component: () => import("../views/home.vue") },

  {
    path: "/auth/sign_in",
    name: "auth_sign_in",
    component: () => import("../views/auth/sign_in.vue"),
  },
  {
    path: "/auth/sign_up",
    name: "auth_sign_up",
    component: () => import("../views/auth/sign_up.vue"),
  },
  {
    path: "/auth/password",
    name: "auth_password",
    component: () => import("../views/auth/password.vue"),
  },
  {
    path: "/auth/confirm",
    name: "auth_confirm",
    component: () => import("../views/auth/confirm.vue"),
  },
  {
    path: "/auth/password/edit",
    name: "auth_password_edit",
    component: () => import("../views/auth/password/edit.vue"),
  },
  {
    path: "/auth/verify",
    name: "auth_verify",
    component: () => import("../views/auth/verify.vue"),
  },
  {
    path: "/auth/cli/:token",
    name: "auth_cli",
    component: () => import("../views/auth/cli.vue"),
  },
  {
    path: "/auth/otp",
    name: "auth_otp",
    component: () => import("../views/auth/otp.vue"),
  },

  {
    path: "/start/profile",
    name: "onboarding_profile",
    meta: { requires_auth: true },
    component: () => import("../views/onboarding/profile.vue"),
  },
  {
    path: "/start/pending",
    name: "onboarding_pending",
    meta: { requires_auth: true },
    component: () => import("../views/onboarding/pending.vue"),
  },
  {
    path: "/start/documents",
    name: "onboarding_documents",
    meta: { requires_auth: true },
    component: () => import("../views/onboarding/documents.vue"),
  },
  {
    path: "/start/declined",
    name: "onboarding_declined",
    meta: { requires_auth: true },
    component: () => import("../views/onboarding/declined.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: { requires_auth: true },
    component: () => import("../views/dashboard/index.vue"),
  },

  {
    path: "/integrations",
    name: "integrations",
    meta: { requires_auth: true },
    component: () => import("../views/integrations/index.vue"),
  },
  {
    path: "/integrations/new",
    name: "new_integration",
    meta: { requires_auth: true },
    component: () => import("../views/integrations/new.vue"),
  },
  {
    path: "/integrations/:uuid",
    name: "integration",
    meta: { requires_auth: true },
    component: () => import("../views/integrations/show.vue"),
  },
  {
    path: "/integrations/redirect",
    name: "integration_redirect",
    meta: { requires_auth: true },
    component: () => import("../views/integrations/redirect.vue"),
  },

  {
    path: "/projects",
    name: "projects",
    meta: { requires_auth: true },
    component: () => import("../views/projects/index.vue"),
  },
  {
    path: "/projects/new",
    name: "new_project",
    meta: { requires_auth: true },
    component: () => import("../views/projects/new.vue"),
  },
  {
    path: "/projects/:uuid",
    name: "project",
    meta: { requires_auth: true },
    component: () => import("../views/projects/show.vue"),
  },
  {
    path: "/projects/:uuid/members",
    name: "project_members",
    meta: { requires_auth: true },
    component: () => import("../views/projects/members/index.vue"),
  },
  {
    path: "/projects/:uuid/settings",
    name: "project_settings",
    meta: { requires_auth: true },
    component: () => import("../views/projects/settings/index.vue"),
  },
  {
    path: "/projects/:uuid/security",
    name: "project_security",
    meta: { requires_auth: true },
    component: () => import("../views/projects/security/index.vue"),
  },
  {
    path: "/projects/:uuid/quotas",
    name: "project_quotas",
    meta: { requires_auth: true },
    component: () => import("../views/projects/quotas/index.vue"),
  },

  {
    path: "/user/locked",
    name: "user_locked",
    meta: { requires_auth: true },
    component: () => import("../views/user/locked.vue"),
  },
  {
    path: "/user/account",
    name: "user_account",
    meta: { requires_auth: true },
    component: () => import("../views/user/account/index.vue"),
  },
  {
    path: "/user/billing",
    name: "user_billing",
    meta: { requires_auth: true },
    component: () => import("../views/user/billing/index.vue"),
  },
  {
    path: "/user/billing/usage",
    name: "user_billing_usage",
    meta: { requires_auth: true },
    component: () => import("../views/user/billing/usage.vue"),
  },

  {
    path: "/user/billing/usage_reports/:uuid",
    name: "user_billing_usage_report",
    meta: { requires_auth: true },
    component: () => import("../views/user/billing/usage_reports/show.vue"),
  },

  {
    path: "/user/security",
    name: "user_security",
    meta: { requires_auth: true },
    component: () => import("../views/user/security/index.vue"),
  },

  {
    path: "/user/quotas",
    name: "user_quotas",
    meta: { requires_auth: true },
    component: () => import("../views/user/quotas/index.vue"),
  },

  {
    path: "/applications",
    name: "applications",
    meta: { requires_auth: true },
    component: () => import("../views/applications/index.vue"),
  },
  {
    path: "/applications/new",
    name: "new_applications",
    meta: { requires_auth: true },
    component: () => import("../views/applications/new.vue"),
  },
  {
    path: "/applications/:uuid",
    name: "application",
    meta: { requires_auth: true },
    component: () => import("../views/applications/show.vue"),
  },

  {
    path: "/applications/:uuid/scaling",
    name: "application_scaling",
    meta: { requires_auth: true },
    component: () => import("../views/applications/scaling/index.vue"),
  },
  {
    path: "/applications/:uuid/metrics",
    name: "application_metrics",
    meta: { requires_auth: true },
    component: () => import("../views/applications/metrics/index.vue"),
  },
  {
    path: "/applications/:uuid/logs",
    name: "application_logs",
    meta: { requires_auth: true },
    component: () => import("../views/applications/logs/index.vue"),
  },
  {
    path: "/applications/:uuid/settings",
    name: "application_settings",
    meta: { requires_auth: true },
    component: () => import("../views/applications/settings/index.vue"),
  },
  {
    path: "/applications/:uuid/workers",
    name: "application_workers",
    meta: { requires_auth: true },
    component: () => import("../views/applications/workers/index.vue"),
  },
  {
    path: "/applications/:uuid/schedules",
    name: "application_schedules",
    meta: { requires_auth: true },
    component: () => import("../views/applications/schedules/index.vue"),
  },
  {
    path: "/applications/:uuid/services",
    name: "application_services",
    meta: { requires_auth: true },
    component: () => import("../views/applications/services/index.vue"),
  },

  {
    path: "/databases",
    name: "databases",
    meta: { requires_auth: true },
    component: () => import("../views/databases/index.vue"),
  },
  {
    path: "/databases/new",
    name: "new_database",
    meta: { requires_auth: true },
    component: () => import("../views/databases/new.vue"),
  },
  {
    path: "/databases/:uuid",
    name: "database",
    meta: { requires_auth: true },
    component: () => import("../views/databases/show.vue"),
  },

  {
    path: "/databases/:uuid/metrics",
    name: "database_metrics",
    meta: { requires_auth: true },
    component: () => import("../views/databases/metrics/index.vue"),
  },
  {
    path: "/databases/:uuid/backups",
    name: "database_backups",
    meta: { requires_auth: true },
    component: () => import("../views/databases/backups/index.vue"),
  },

  {
    path: "/buckets",
    name: "buckets",
    meta: { requires_auth: true },
    component: () => import("../views/buckets/index.vue"),
  },
  {
    path: "/buckets/new",
    name: "new_bucket",
    meta: { requires_auth: true },
    component: () => import("../views/buckets/new.vue"),
  },
  {
    path: "/buckets/:uuid",
    name: "bucket",
    meta: { requires_auth: true },
    component: () => import("../views/buckets/show.vue"),
  },

  {
    path: "/buckets/:uuid/settings",
    name: "bucket_settings",
    meta: { requires_auth: true },
    component: () => import("../views/buckets/settings/index.vue"),
  },

  {
    path: "/dns_zones",
    name: "dns_zones",
    meta: { requires_auth: true },
    component: () => import("../views/dns_zones/index.vue"),
  },
  {
    path: "/dns_zones/new",
    name: "new_dns_zone",
    meta: { requires_auth: true },
    component: () => import("../views/dns_zones/new.vue"),
  },
  {
    path: "/dns_zones/:uuid",
    name: "dns_zone",
    meta: { requires_auth: true },
    component: () => import("../views/dns_zones/show.vue"),
  },

  {
    path: "/tickets",
    name: "tickets",
    meta: { requires_auth: true },
    component: () => import("../views/tickets/index.vue"),
  },
  {
    path: "/tickets/new",
    name: "new_ticket",
    meta: { requires_auth: true },
    component: () => import("../views/tickets/new.vue"),
  },
  {
    path: "/tickets/:uuid",
    name: "ticket",
    meta: { requires_auth: true },
    component: () => import("../views/tickets/show.vue"),
  },

  {
    path: "/sla",
    name: "sla",
    meta: { requires_auth: true },
    component: () => import("../views/sla/index.vue"),
  },

  {
    path: "/domains",
    name: "domains",
    meta: { requires_auth: true },
    component: () => import("../views/domains/index.vue"),
  },
  {
    path: "/domain_contacts",
    name: "domain_contacts",
    meta: { requires_auth: true },
    component: () => import("../views/domains/domain_contacts/index.vue"),
  },
  {
    path: "/tlds",
    name: "tlds",
    meta: { requires_auth: true },
    component: () => import("../views/domains/tlds/index.vue"),
  },

  {
    path: "/observers",
    name: "observers",
    meta: { requires_auth: true },
    component: () => import("../views/observers/index.vue"),
  },
  {
    path: "/observers/new",
    name: "new_observer",
    meta: { requires_auth: true },
    component: () => import("../views/observers/new.vue"),
  },
  {
    path: "/observers/:uuid",
    name: "observer",
    meta: { requires_auth: true },
    component: () => import("../views/observers/show.vue"),
  },

  {
    path: "/observers/:uuid/notifiers",
    name: "observer_notifiers",
    meta: { requires_auth: true },
    component: () => import("../views/observers/notifiers/index.vue"),
  },
  {
    path: "/observers/:uuid/anomalies",
    name: "observer_anomalies",
    meta: { requires_auth: true },
    component: () => import("../views/observers/anomalies/index.vue"),
  },

  {
    path: "/cdn_zones",
    name: "cdn_zones",
    meta: { requires_auth: true },
    component: () => import("../views/cdn_zones/index.vue"),
  },
  {
    path: "/cdn_zones/new",
    name: "new_cdn_zone",
    meta: { requires_auth: true },
    component: () => import("../views/cdn_zones/new.vue"),
  },
  {
    path: "/cdn_zones/:uuid",
    name: "cdn_zone",
    meta: { requires_auth: true },
    component: () => import("../views/cdn_zones/show.vue"),
  },

  {
    path: "/cdn_zones/:uuid/domains",
    name: "cdn_zone_domains",
    meta: { requires_auth: true },
    component: () => import("../views/cdn_zones/domains/index.vue"),
  },
  {
    path: "/cdn_zones/:uuid/settings",
    name: "cdn_zone_settings",
    meta: { requires_auth: true },
    component: () => import("../views/cdn_zones/settings/index.vue"),
  },
  {
    path: "/cdn_zones/:uuid/statistics",
    name: "cdn_zone_statistics",
    meta: { requires_auth: true },
    component: () => import("../views/cdn_zones/statistics/index.vue"),
  },
];

const router = createRouter({
  history: createWebHistory("/"),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requires_auth)) {
    if (!store.getters.is_logged_in) {
      next({ name: "auth_sign_in" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
